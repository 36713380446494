import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  galeriler: [],
};
const getters = {
  getGaleriler: (state) => state.galeriler,
};
const mutations = {
  SET_GALERILER(state, payload) {
    state.galeriler = payload;
  },
  SET_GALERILER_PUSH(state, payload) {
    state.galeriler.push(payload);
  },
  SET_GALERILER_UPDATE(state, payload) {
    let index = state.galeriler.findIndex((x) => x.k_no == payload.k_no);
    state.galeriler[index] = payload;
  },
  SET_GALERILER_DELETE(state, k_no) {
    let altindex = state.galeriler.findIndex((x) => x.galeri_k_no == k_no);
    if (altindex != -1) {
      state.galeriler.splice(altindex, 1);
    }
    let index = state.galeriler.findIndex((x) => x.k_no == k_no);
    state.galeriler.splice(index, 1);
  },
};
const actions = {
  async galeriListele({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/web-yonetim/galeri-listele', payload).then((res) => {
      commit('SET_GALERILER', res.data.data);
      return res;
    });
  },
  async galeriEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/web-yonetim/galeri-ekle', payload).then((res) => {
      commit('SET_GALERILER_PUSH', res.data.data);
      return res;
    });
  },
  async galeriGuncelle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/web-yonetim/galeri-guncelle', payload).then((res) => {
      commit('SET_GALERILER_UPDATE', res.data.data);
      return res;
    });
  },
  async galeriResimEkle({ commit }, data) {
    data.append('firma_k_no', store.getters.getUserData.firma_k_no);
    return await axiosIns
      .post('/web-yonetim/galeri-resim-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_GALERILER_UPDATE', res.data.data);
        return res;
      });
  },
  async galeriSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/web-yonetim/galeri-sil', payload).then((res) => {
      commit('SET_GALERILER_DELETE', k_no);
      return res;
    });
  },

  async galeriResimSil({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/web-yonetim/galeri-resim-sil', payload).then((res) => {
      commit('SET_GALERILER_UPDATE', res.data.data);
      return res;
    });
  },

  async galeriResimSiraGuncelle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/web-yonetim/galeri-resim-sira-guncelle', payload).then((res) => {
      commit('SET_GALERILER_UPDATE', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
