import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  slider: [],
};
const getters = {
  getSlider: (state) => {
    return state.slider;
  },
};
const mutations = {
  SET_SLIDER(state, payload) {
    state.slider = payload;
  },
  SET_SLIDER_PUSH(state, payload) {
    state.slider.push(payload);
  },
  SET_SLIDER_UPDATE(state, payload) {
    let index = state.slider.findIndex((x) => x.k_no == payload.k_no);
    state.slider[index] = payload;
  },
  SET_SLIDER_DELETE(state, k_no) {
    let index = state.slider.findIndex((x) => x.k_no == k_no);
    state.slider.splice(index, 1);
  },
};
const actions = {
  async slideListele({ commit }, data) {
    return await axiosIns.post('/web-yonetim/slide-listele', data).then((res) => {
      commit('SET_SLIDER', res.data.data);
      return res;
    });
  },
  async slideEkle({ commit }, data) {
    return await axiosIns
      .post('/web-yonetim/slide-ekle', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        const data = res.data.data;
        data.forEach((el) => {
          console.log(el);
          commit('SET_SLIDER_PUSH', el);
        });
        return res;
      });
  },
  async slideGuncelle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/web-yonetim/slide-guncelle', payload).then((res) => {
      commit('SET_SLIDER_UPDATE', res.data.data);
      return res;
    });
  },
  async slideSiraGuncelle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/web-yonetim/slide-sira-guncelle', payload).then((res) => {
      commit('SET_SLIDER', res.data.data);
      return res;
    });
  },
  async slideSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/web-yonetim/slide-sil', payload).then((res) => {
      commit('SET_SLIDER_DELETE', k_no);
      return res;
    });
  },
  async slideFindOne({}, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/web-yonetim/slide-find-one', payload).then((res) => {
      if (res.data.success === true) return res.data.data;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
