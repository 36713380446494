import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  diger_ayarlar: [],
};
const getters = {
  getDigerAyarlar: (state) => state.diger_ayarlar,
};
const mutations = {
  SET_DIGER(state, payload) {
    state.diger_ayarlar = payload;
  },
  SET_DIGER_DELETE(state, k_no) {
    let index = state.diger_ayarlar.findIndex((x) => x.k_no == k_no);
    state.diger_ayarlar.splice(index, 1);
  },
};
const actions = {
  async digerAyarlariGetir({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/diger-ayarlari-getir', payload).then((res) => {
      commit('SET_DIGER', res.data.data);
      return res;
    });
  },
  async digerAyarlariEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/diger-ayarlari-ekle', payload).then((res) => {
      commit('SET_DIGER', res.data.data);
      return res;
    });
  },
  async digerAyarlariSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/ayarlar/diger-ayarlar-sil', payload).then((res) => {
      commit('SET_DIGER_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
