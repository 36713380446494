import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  sosyal_medya: [],
};
const getters = {
  getSosyalMedyaListele: (state) => state.sosyal_medya,
};
const mutations = {
  SET_SOSYAL_MEDYA(state, payload) {
    state.sosyal_medya = payload;
  },
  SET_SOSYAL_MEDYA_PUSH(state, payload) {
    state.sosyal_medya.push(payload);
  },
  SET_SOSYAL_MEDYA_UPDATE(state, payload) {
    let index = state.sosyal_medya.findIndex((x) => x.k_no == payload.k_no);
    state.sosyal_medya[index] = payload;
  },
  SET_SOSYAL_MEDYA_DELETE(state, k_no) {
    let index = state.sosyal_medya.findIndex((x) => x.k_no == k_no);
    state.sosyal_medya.splice(index, 1);
  },
};
const actions = {
  async sosyalMedyaListele({ commit }) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
    };
    return await axiosIns.post('/ayarlar/sosyal-medya-listele', payload).then((res) => {
      if (res.data.success == true) {
        commit('SET_SOSYAL_MEDYA', res.data.data);
      }
    });
  },
  async sosyalMedyaEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/sosyal-medya-ekle', payload).then((res) => {
      commit('SET_SOSYAL_MEDYA_PUSH', res.data.data);
      return res;
    });
  },
  async sosyalMedyaGuncelle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/sosyal-medya-guncelle', payload).then((res) => {
      commit('SET_SOSYAL_MEDYA_UPDATE', res.data.data);
      return res;
    });
  },
  async sosyalMedyaSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/ayarlar/sosyal-medya-sil', payload).then((res) => {
      commit('SET_SOSYAL_MEDYA_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
