const state = {
  locale: 'tr',
  fallBackLocale: 'en',
};

const getters = {
  getLocale: (state) => state.locale,
  getFallBackLocale: (state) => state.fallBackLocale,
};

const mutations = {
  SET_LOCALE(state, locale) {
    state.locale = locale;
  },
};

const actions = {};

export default {
  state,
  getters,
  actions,
  mutations,
};
