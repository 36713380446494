import useJwt from '@/auth/jwt/useJwt';
import router from '@/router';
const state = {
  user_data: {},
  user_role: {},
  user_firma: {},
  url: null,
};
const getters = {
  getUserData: (state) => state.user_data,
  getUserRole: (state) => state.user_role,
  getUserFirma: (state) => state.user_firma,
};
const mutations = {
  SET_USER_DATA(state, payload) {
    state.user_data = payload;
  },

  SET_SELECT_FIRMA_DB(state, payload) {
    state.user_firma.db = payload;
  },

  SET_USER_ROLE(state, payload) {
    state.user_role = payload;
  },

  SET_USER_FIRMA(state, payload) {
    state.user_firma = payload;
  },

  SET_URL(state, payload) {
    state.url = payload;
  },
};
const actions = {
  login({ commit, dispatch, getters }, data) {
    return useJwt.login(data).then(async (res) => {
      if (res.data.success == true) {
        const { VUE_APP_DEFAULT_DB } = process.env;
        useJwt.setToken(res.data.access_token);
        const userData = res.data.data.user;
        const userRole = res.data.data.role;
        const userFirma = res.data.data.firma || {};
        localStorage.setItem('userData', JSON.stringify(userData));
        commit('SET_USER_DATA', userData);
        commit('SET_USER_ROLE', userRole);

        if (userData.master == 'admin') userFirma.db = VUE_APP_DEFAULT_DB;

        commit('SET_USER_FIRMA', userFirma);

        if (userData.master != 'admin') dispatch('dilAyarlariGetir');
        return res;
      }
    });
  },

  logout() {
    const { JWT_SECRET_KEY } = process.env;
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

    localStorage.removeItem('userData');
    localStorage.removeItem(JWT_SECRET_KEY);
    router.push({ name: 'login' });
  },

  initAuth({ dispatch, commit, state, getters }, route) {
    const token = useJwt.getToken();
    if (token) {
      useJwt
        .checkToken({ token })
        .then(async (res) => {
          if (res.data.success == true) {
          } else {
            dispatch('redirectAndRemove');
          }
        })
        .catch((err) => {
          if (err.status === 401 && err.data.success === false) {
            dispatch('redirectAndRemove');
          }
        });
    } else {
      dispatch('redirectAndRemove');
    }
  },

  redirectAndRemove() {
    const { JWT_SECRET_KEY } = process.env;
    localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
    localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

    localStorage.removeItem('userData');
    localStorage.removeItem(JWT_SECRET_KEY);
    if (state.url !== 'reset-password' && state.url !== 'forgot-password') {
      router.push({ name: 'login' });
    }
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
