import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  odeme_ayarlari: [],
  odeme_banka_bilgileri: [],
};
const getters = {
  getOdemeAyarlari: (state) => state.odeme_ayarlari,
  getOdemeBankaBilgi: (state) => state.odeme_banka_bilgileri,
};
const mutations = {
  SET_ODEME(state, payload) {
    state.odeme_ayarlari = payload;
  },
  SET_ODEME_DELETE(state, k_no) {
    let index = state.odeme_ayarlari.findIndex((x) => x.k_no == k_no);
    state.odeme_ayarlari.splice(index, 1);
  },
  SET_BANKA_BILGI(state, payload) {
    state.odeme_banka_bilgileri = payload;
  },
  SET_BANKA_BILGI_PUSH(state, payload) {
    state.odeme_banka_bilgileri.push(payload);
  },
  SET_BANKA_BILGI_UPDATE(state, payload) {
    let index = state.odeme_banka_bilgileri.findIndex((x) => x.k_no == payload.k_no);
    state.odeme_banka_bilgileri[index] = payload;
  },
  SET_BANKA_BILGI_DELETE(state, k_no) {
    let index = state.odeme_banka_bilgileri.findIndex((x) => x.k_no == k_no);

    state.odeme_banka_bilgileri.splice(index, 1);
  },
};
const actions = {
  async odemeAyarlariGetir({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/odeme-ayarlari-getir', payload).then((res) => {
      commit('SET_ODEME', res.data.data);
      return res;
    });
  },
  async odemeAyarlariEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/odeme-ayarlari-ekle', payload).then((res) => {
      commit('SET_ODEME', res.data.data);
      return res;
    });
  },
  async odemeAyarlariSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/ayarlar/odeme-ayarlar-sil', payload).then((res) => {
      commit('SET_SMS_DELETE', k_no);
      return res;
    });
  },
  async odemeBankaBilgiListele({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/odeme-banka-bilgi-listele', payload).then((res) => {
      commit('SET_BANKA_BILGI', res.data.data);
      return res;
    });
  },
  async odemeBankaBilgiEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/odeme-banka-bilgi-ekle', payload).then((res) => {
      commit('SET_BANKA_BILGI_PUSH', res.data.data);
      return res;
    });
  },
  async odemeBankaBilgiGuncelle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/odeme-banka-bilgi-guncelle', payload).then((res) => {
      commit('SET_BANKA_BILGI_UPDATE', res.data.data);
      return res;
    });
  },
  async odemeBankaBilgiSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/ayarlar/odeme-banka-bilgi-sil', { k_no }).then((res) => {
      commit('SET_BANKA_BILGI_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
