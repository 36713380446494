import { $themeBreakpoints } from '@themeConfig';

const state = {
  windowWidth: 0,
  shallShowOverlay: false,
  locale: 'tr',
};

const getters = {
  getLocale: (state) => state.locale,
  currentBreakPoint: (state) => {
    const { windowWidth } = state;
    if (windowWidth >= $themeBreakpoints.xl) return 'xl';
    if (windowWidth >= $themeBreakpoints.lg) return 'lg';
    if (windowWidth >= $themeBreakpoints.md) return 'md';
    if (windowWidth >= $themeBreakpoints.sm) return 'sm';
    return 'xs';
  },
};

const mutations = {
  UPDATE_WINDOW_WIDTH(state, val) {
    state.windowWidth = val;
  },
  TOGGLE_OVERLAY(state, val) {
    state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay;
  },
  SET_LOCALE(state, locale) {
    state.locale = locale;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
