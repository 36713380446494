import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  rezervasyonlar: [],
};
const getters = {
  getRezervasyonlar: (state) => state.rezervasyonlar,
};
const mutations = {
  SET_REZERVAYON(state, payload) {
    state.rezervasyonlar = payload;
  },
  SET_REZERVAYON_PUSH(state, payload) {
    state.rezervasyonlar.push(payload);
  },
  SET_REZERVAYON_UPDATE(state, payload) {
    let index = state.rezervasyonlar.findIndex((x) => x.k_no == payload.k_no);
    state.rezervasyonlar[index] = payload;
  },
  SET_REZERVAYON_DELETE(state, k_no) {
    let index = state.rezervasyonlar.findIndex((x) => x.k_no == k_no);
    state.rezervasyonlar.splice(index, 1);
  },
};
const actions = {
  async rezervasyonListele({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/rezervasyon/rezervasyon-listele', payload).then((res) => {
      commit('SET_REZERVAYON', res.data.data);
      return res;
    });
  },
  async rezervasyonEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/rezervasyon/rezervasyon-ekle', payload).then((res) => {
      commit('SET_REZERVAYON_PUSH', res.data.data);
      return res;
    });
  },
  async rezervasyonGuncelle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/rezervasyon/rezervasyon-guncelle', payload).then((res) => {
      commit('SET_REZERVAYON_UPDATE', res.data.data);
      return res;
    });
  },
  async rezervasyonSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/rezervasyon/rezervasyon-sil', payload).then((res) => {
      commit('SET_REZERVAYON_DELETE', k_no);
      return res;
    });
  },
  async rezervasyonStatuDurum({}, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/rezervasyon/rezervasyon-statu-durum', payload).then((res) => {
      return res;
    });
  },
  async rezervasyonOdemeDurum({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/rezervasyon/rezervasyon-odeme-durum', payload).then((res) => {
      return res;
    });
  },
  async handlerRezervasyonFindOne({}, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/rezervasyon/rezervasyon-find-one', payload).then((res) => {
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
