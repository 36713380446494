import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  menuler: [],
};
const getters = {
  getMenuListele: (state) => state.menuler,
};
const mutations = {
  SET_MENU(state, payload) {
    state.menuler = payload;
  },
  SET_MENU_PUSH(state, payload) {
    state.menuler.push(payload);
  },
  SET_MENU_UPDATE(state, payload) {
    let index = state.menuler.findIndex((x) => x.k_no == payload.k_no);
    state.menuler[index] = payload;
  },
  SET_MENU_DELETE(state, k_no) {
    let index = state.menuler.findIndex((x) => x.k_no == k_no);
    state.menuler.splice(index, 1);
  },
};
const actions = {
  async menuListele({ commit }) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
    };
    return await axiosIns.post('/ayarlar/menu-listele', payload).then((res) => {
      if (res.data.success == true) {
        commit('SET_MENU', res.data.data);
      }
    });
  },
  async menuEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/menu-ekle', payload).then((res) => {
      commit('SET_MENU_PUSH', res.data.data);
      return res;
    });
  },
  async menuGuncelle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/menu-guncelle', payload).then((res) => {
      commit('SET_MENU_UPDATE', res.data.data);
      return res;
    });
  },
  async menuSiraGuncelle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/menu-sira-guncelle', payload).then((res) => {
      commit('SET_MENU', res.data.data);
      return res;
    });
  },
  async menuSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };

    return await axiosIns.post('/ayarlar/menu-sil', payload).then((res) => {
      commit('SET_MENU_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
