import axiosIns from '@/libs/axios';
import store from '@/store';
import { diller } from '@/libs/languages';
const state = {
  dil_ayarlari: [],
};
const getters = {
  getDilAyarlari: (state) => state.dil_ayarlari,
};
const mutations = {
  SET_DIL_AYARLARI(state, payload) {
    state.dil_ayarlari = payload;
  },
};
const actions = {
  async dilAyarlariGetir({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/dil-ayarlari-getir', payload).then((res) => {
      if (res.data.data != null) {
        commit('SET_DIL_AYARLARI', res.data.data);
      } else {
        const data = {
          diller,
          varsayilan: 'tr',
        };
        commit('SET_DIL_AYARLARI', data);
      }
      return res;
    });
  },
  async dilAyarlariEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/dil-ayarlari-ekle', payload).then((res) => {
      commit('SET_DIL_AYARLARI', res.data.data);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
