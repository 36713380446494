import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  ekstra_hizmetler: [],
};
const getters = {
  getEkstraHizmetler: (state) => state.ekstra_hizmetler,
};
const mutations = {
  SET_EKSTRA_HIZMET(state, payload) {
    state.ekstra_hizmetler = payload;
  },
  SET_EKSTRA_HIZMET_PUSH(state, payload) {
    state.ekstra_hizmetler.push(payload);
  },
  SET_EKSTRA_HIZMET_UPDATE(state, payload) {
    let index = state.ekstra_hizmetler.findIndex((x) => x.k_no == payload.k_no);
    state.ekstra_hizmetler[index] = payload;
  },
  SET_EKSTRA_HIZMET_DELETE(state, k_no) {
    let index = state.ekstra_hizmetler.findIndex((x) => x.k_no == k_no);
    state.ekstra_hizmetler.splice(index, 1);
  },
};
const actions = {
  async ekstraHizmetListele({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/ekstra-hizmet-listele', payload).then((res) => {
      commit('SET_EKSTRA_HIZMET', res.data.data);
      return res;
    });
  },
  async ekstraHizmetEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/ekstra-hizmet-ekle', payload).then((res) => {
      commit('SET_EKSTRA_HIZMET_PUSH', res.data.data);
      return res;
    });
  },
  async ekstraHizmetGuncelle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/ekstra-hizmet-guncelle', payload).then((res) => {
      commit('SET_EKSTRA_HIZMET_UPDATE', res.data.data);
      return res;
    });
  },
  async ekstraHizmetSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/ekstra-hizmet-sil', payload).then((res) => {
      commit('SET_EKSTRA_HIZMET_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
