import axiosIns from '@/libs/axios';
const state = {
  kur_turleri: [],
  kur_listesi: {},
  anlik_kur_listesi: [],
};
const getters = {
  getKurTurleri: (state) => state.kur_turleri,
  getKurListesi: (state) => state.kur_listesi,
  getAnlikKurListesi: (state) => state.anlik_kur_listesi,
};
const mutations = {
  // Kur Tur İşlemleri
  SET_KUR_TURLERI(state, payload) {
    state.kur_turleri = payload;
  },
  SET_KUR_TURLERI_PUSH(state, payload) {
    state.kur_turleri.push(payload);
  },
  SET_KUR_TURLERI_UPDATE(state, payload) {
    let index = state.kur_turleri.findIndex((x) => x.k_no == payload.k_no);
    state.kur_turleri[index] = payload;
  },
  SET_KUR_TURLERI_DELETE(state, id) {
    let index = state.kur_turleri.findIndex((x) => x._id == id);
    state.kur_turleri.splice(index, 1);
  },

  // Kur Tur İşlemleri
  SET_ANLIK_KUR(state, payload) {
    state.anlik_kur_listesi = payload;
  },
  SET_ANLIK_KUR_PUSH(state, payload) {
    state.anlik_kur_listesi.push(payload);
  },

  // Kur Tur İşlemleri
  SET_KUR(state, payload) {
    state.kur_listesi = payload;
  },
  SET_KUR_PUSH(state, payload) {
    state.kur_listesi.push(payload);
  },
};
const actions = {
  // Kur Tur İşlemleri
  async kurTurleriListele({ commit }) {
    return await axiosIns.post('/yonetim/kur-turleri-listele').then((res) => {
      if (res.data.success == true) {
        commit('SET_KUR_TURLERI', res.data.data);
      }
    });
  },
  async kurTurleriEkle({ commit }, payload) {
    return await axiosIns.post('/yonetim/kur-turleri-ekle', payload).then((res) => {
      commit('SET_KUR_TURLERI_PUSH', res.data.data);
      return res;
    });
  },
  async kurTurleriGuncelle({ commit }, payload) {
    return await axiosIns.post('/yonetim/kur-turleri-guncelle', payload).then((res) => {
      commit('SET_KUR_TURLERI_UPDATE', res.data.data[0]);
      return res;
    });
  },
  async kurTurleriSil({ commit }, id) {
    return await axiosIns.post('/yonetim/kur-turleri-sil', { id }).then((res) => {
      commit('SET_KUR_TURLERI_DELETE', id);
      return res;
    });
  },

  // Anlık Kur İşlemleri
  async anlikKurListesi({ commit }) {
    return await axiosIns.get('/yonetim/anlik-kur-cek').then((res) => {
      if (res.data.success == true) {
        commit('SET_ANLIK_KUR', res.data.data);
      }
    });
  },

  async gunlukKurListele({ commit }, payload) {
    return await axiosIns.post('/yonetim/gunluk-kur-listele', payload).then((res) => {
      if (res.data.success === true) {
        commit('SET_KUR', res.data.data[0]);
      } else {
        commit('SET_KUR', {});
      }
      return res;
    });
  },
  async gunlukKurEkle({ commit }, payload) {
    return await axiosIns.post('/yonetim/gunluk-kur-ekle', payload).then((res) => {
      commit('SET_KUR_TURLERI_PUSH', res.data.data);
      return res;
    });
  },
};

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
};
