export default [
  {
    path: '/urun',
    name: 'urun',
    redirect: '/urun/listele',
    component: () => import(/* webpackChunkName: "urun" */ '@/views/urunler/index.vue'),
    children: [
      {
        path: '/urun/listele',
        name: 'urun-listele',
        component: () => import(/* webpackChunkName: "urunler" */ '@/views/urunler/urunler.vue'),
        meta: {
          contentRenderer: 'sidebar-left-detached',
          contentClass: 'ecommerce-application',
        },
      },
      {
        path: '/urun/sirala',
        name: 'urun-siralama',
        component: () => import(/* webpackChunkName: "urunSiralama" */ '@/views/urunler/urunSiralama.vue'),
      },
      {
        path: '/urun/ekle',
        name: 'urun-ekle',
        component: () => import(/* webpackChunkName: "urun-ekle" */ '@/views/urunler/urunTanimla/index.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
        },
        redirect: '/urun/ekle/bilgiler',
        children: [
          {
            path: '/urun/ekle/bilgiler',
            name: 'urun-ekle',
            component: () => import(/* webpackChunkName: "urun-bilgisi" */ '@/views/urunler/urunTanimla/urunBilgisi.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'ekle',
            },
          },
        ],
      },
      {
        path: '/urun/guncelle/:k_no?',
        name: 'urun-guncelle',
        component: () => import(/* webpackChunkName: "urun-ekle" */ '@/views/urunler/urunTanimla/index.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
        },
        redirect: '/urun/guncelle/:k_no?/bilgiler',
        children: [
          {
            path: '/urun/guncelle/:k_no?/bilgiler',
            name: 'urun-bilgisi',
            component: () => import(/* webpackChunkName: "urun-bilgisi" */ '@/views/urunler/urunTanimla/urunBilgisi.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'guncelle',
            },
          },
          {
            path: '/urun/guncelle/:k_no?/fotograflar',
            name: 'urun-fotograflar',
            component: () => import(/* webpackChunkName: "urun-fotograflar" */ '@/views/urunler/urunTanimla/fotograflar.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'guncelle',
            },
          },
          {
            path: '/urun/guncelle/:k_no?/tarih-fiyat',
            name: 'tarih-fiyat',
            component: () => import(/* webpackChunkName: "tarih-fiyat" */ '@/views/urunler/urunTanimla/tarihFiyat.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'guncelle',
            },
          },
          {
            path: '/urun/guncelle/:k_no?/dahil-haric',
            name: 'dahil-haric',
            component: () => import(/* webpackChunkName: "dahil-haric" */ '@/views/urunler/urunTanimla/dahilHaricHizmetler.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'guncelle',
            },
          },
          {
            path: '/urun/guncelle/:k_no?/iptal-politika',
            name: 'iptal-politika',
            component: () => import(/* webpackChunkName: "iptal-politika" */ '@/views/urunler/urunTanimla/iptalPolitikalari.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'guncelle',
            },
          },
          {
            path: '/urun/guncelle/:k_no?/tag',
            name: 'tag',
            component: () => import(/* webpackChunkName: "tag" */ '@/views/urunler/urunTanimla/taglar.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'guncelle',
            },
          },
          {
            path: '/urun/guncelle/:k_no?/dil-secenekleri',
            name: 'dil-secenekleri',
            component: () => import(/* webpackChunkName: "dil-secenekleri" */ '@/views/urunler/urunTanimla/dilSecenekleri.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'guncelle',
            },
          },
          {
            path: '/urun/guncelle/:k_no?/guzergah-bilgisi',
            name: 'guzergah-bilgisi',
            component: () => import(/* webpackChunkName: "guzergah-bilgisi" */ '@/views/urunler/urunTanimla/guzergahBilgisi.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'guncelle',
            },
          },
          {
            path: '/urun/guncelle/:k_no?/diger-bilgiler',
            name: 'diger-bilgiler',
            component: () => import(/* webpackChunkName: "diger-bilgiler" */ '@/views/urunler/urunTanimla/digerBilgiler.vue'),
            meta: {
              contentRenderer: 'sidebar-left',
              contentClass: 'todo-application',
              navActiveLink: 'guncelle',
            },
          },
        ],
      },
    ],
  },
];
