export default [
  //TANIMLAMALAR
  {
    path: '/rezervasyon/listele',
    name: 'rezervasyonlar',
    component: () => import(/* webpackChunkName: "rezervasyon-listele" */ '@/views/rezervasyonlar/index.vue'),
  },
  {
    path: '/rezervasyon/detay/:k_no',
    name: 'rezervasyon-detay',
    component: () => import(/* webpackChunkName: "rezervasyon-detay" */ '@/views/rezervasyonlar/rezervasyonDetay.vue'),
  },
];
