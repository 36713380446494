import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  yorumlar: [],
};
const getters = {
  getYorumlar: (state) => state.yorumlar,
};
const mutations = {
  SET_YORUMLAR(state, payload) {
    state.yorumlar = payload;
  },
  SET_YORUMLAR_PUSH(state, payload) {
    state.yorumlar.push(payload);
  },
  SET_YORUMLAR_DELETE(state, k_no) {
    let index = state.yorumlar.findIndex((x) => x.k_no == k_no);
    state.yorumlar.splice(index, 1);
  },
};
const actions = {
  async yorumListele({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/yorumlar/yorum-listele', payload).then((res) => {
      commit('SET_YORUMLAR', res.data.data);
      return res;
    });
  },

  async yorumSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/yorumlar/yorum-sil', payload).then((res) => {
      commit('SET_YORUMLAR_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
