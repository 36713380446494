export default [
  //TANIMLAMALAR
  {
    path: '/uye/listele',
    name: 'uyeler',
    component: () => import(/* webpackChunkName: "rezervasyon-listele" */ '@/views/uyeler/index.vue'),
  },
  {
    path: '/uye/detay/:k_no',
    name: 'uye-detay',
    component: () => import(/* webpackChunkName: "uye-detay" */ '@/views/uyeler/uyeDetay.vue'),
  },
];
