import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  uyeler: [],
};
const getters = {
  getUyeler: (state) => state.uyeler,
};
const mutations = {
  SET_UYELER(state, payload) {
    state.uyeler = payload;
  },
  SET_UYELER_PUSH(state, payload) {
    state.uyeler.push(payload);
  },
  SET_UYELER_UPDATE(state, payload) {
    let index = state.uyeler.findIndex((x) => x.k_no == payload.k_no);
    state.uyeler[index] = payload;
  },
  SET_UYELER_DELETE(state, k_no) {
    let index = state.uyeler.findIndex((x) => x.k_no == k_no);
    state.uyeler.splice(index, 1);
  },
};
const actions = {
  async uyeListele({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/uyeler/uye-listele', payload).then((res) => {
      commit('SET_UYELER', res.data.data);
      return res;
    });
  },

  async uyeFindOne({}, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/uyeler/uye-getir', payload).then((res) => {
      if (res.data.success == true) {
        return res.data.result;
      }
    });
  },

  async uyeEkle({ commit }, payload) {
    return await axiosIns
      .post('/uyeler/uye-ekle', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.success == true) {
          commit('SET_UYELER_PUSH', res.data.data);
        }
        return res;
      });
  },

  async uyeGuncelle({ commit }, payload) {
    return await axiosIns
      .post('/uyeler/uye-guncelle', payload, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('SET_UYELER_UPDATE', res.data.data);
        return res;
      });
  },

  async uyeSil({ commit }, k_no) {
    return await axiosIns.post('/uyeler/uye-sil', { k_no }).then((res) => {
      commit('SET_UYELER_DELETE', k_no);
      return res;
    });
  },

  async uyeYorumlar({}, payload) {
    return await axiosIns.post('/uyeler/uye-yorumlar', payload).then((res) => {
      if (res.data.success == true) {
        return res;
      }
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
