import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  iletisim: [],
};
const getters = {
  getIletisim: (state) => state.iletisim,
};
const mutations = {
  SET_ILETISIM(state, payload) {
    state.iletisim = payload;
  },
  SET_ILETISIM_DELETE(state, k_no) {
    let index = state.iletisim.findIndex((x) => x.k_no == k_no);
    state.iletisim.splice(index, 1);
  },
};
const actions = {
  async iletisimGetir({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/web-yonetim/iletisim-getir', payload).then((res) => {
      commit('SET_ILETISIM', res.data.data);
      return res;
    });
  },
  async iletisimEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/web-yonetim/iletisim-ekle', payload).then((res) => {
      commit('SET_ILETISIM', res.data.data);
      return res;
    });
  },
  async iletisimSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/web-yonetim/sil-sil', payload).then((res) => {
      commit('SET_ILETISIM_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
