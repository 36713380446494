import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  ayar_dashboard: [],
};
const getters = {
  getAyarDashboard: (state) => state.ayar_dashboard,
};
const mutations = {
  SET_AYAR_DASHBOARD(state, payload) {
    state.ayar_dashboard = payload;
  },
  SET_AYAR_DASHBOARD_DELETE(state, id) {
    let index = state.ayar_dashboard.findIndex((x) => x._id == id);
    state.ayar_dashboard.splice(index, 1);
  },
};
const actions = {
  async ayarDashboardGetir({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/ayar-dahsboard-getir', payload).then((res) => {
      commit('SET_AYAR_DASHBOARD', res.data.data);
      return res;
    });
  },
  async ayarDashboardEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/ayarlar/ayar-dahsboard-ekle', payload).then((res) => {
      commit('SET_AYAR_DASHBOARD', res.data.data);
      return res;
    });
  },
  async ayarDashboardSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/ayarlar/ayar-dahsboard-sil', payload).then((res) => {
      commit('SET_AYAR_DASHBOARD_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
