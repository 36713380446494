import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils';
import auth from './routes/auth';
import dashboard from './routes/dashboard';
import webYonetim from './routes/webYonetim';
import urunler from './routes/urunler';
import rezervasyonlar from './routes/rezervasyonlar';
import finans from './routes/finans';
import yorumlar from './routes/yorumlar';
import uyeler from './routes/uyeler';
import tanimlamalar from './routes/tanimlamalar';
import bildirimler from './routes/bildirimler';
import ayarlar from './routes/ayarlar';
import yonetim from './routes/yonetim';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...dashboard,
    ...webYonetim,
    ...urunler,
    ...rezervasyonlar,
    ...finans,
    ...yorumlar,
    ...uyeler,
    ...auth,
    ...tanimlamalar,
    ...bildirimler,
    ...ayarlar,
    ...yonetim,
  ],
});

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  let item = {};
  item.route = to.name;

  store.commit('SET_URL', to.name);

  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? 'admin' : 'login'));
  }

  // Yetkisi yoksa yonlendirme
  // if (isLoggedIn && to.name != 'misc-not-authorized') {
  //   next({ name: 'misc-not-authorized' });
  // }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
