import axiosIns from '@/libs/axios';
import store from '@/store';
const state = {
  iptal_politikalari: [],
};
const getters = {
  getIptalPolitikalari: (state) => state.iptal_politikalari,
};
const mutations = {
  SET_IPTAL_POLITIKA(state, payload) {
    state.iptal_politikalari = payload;
  },
  SET_IPTAL_POLITIKA_PUSH(state, payload) {
    state.iptal_politikalari.push(payload);
  },
  SET_IPTAL_POLITIKA_UPDATE(state, payload) {
    let index = state.iptal_politikalari.findIndex((x) => x.k_no == payload.k_no);
    state.iptal_politikalari[index] = payload;
  },
  SET_IPTAL_POLITIKA_DELETE(state, k_no) {
    let index = state.iptal_politikalari.findIndex((x) => x.k_no == k_no);
    state.iptal_politikalari.splice(index, 1);
  },
};
const actions = {
  async iptalPolitikaListele({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/iptal-politika-listele', payload).then((res) => {
      commit('SET_IPTAL_POLITIKA', res.data.data);
      return res;
    });
  },
  async iptalPolitikaEkle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/iptal-politika-ekle', payload).then((res) => {
      commit('SET_IPTAL_POLITIKA_PUSH', res.data.data);
      return res;
    });
  },
  async iptalPolitikaGuncelle({ commit }, data) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      ...data,
    };
    return await axiosIns.post('/tanimlamalar/iptal-politika-guncelle', payload).then((res) => {
      commit('SET_IPTAL_POLITIKA_UPDATE', res.data.data);
      return res;
    });
  },
  async iptalPolitikaSil({ commit }, k_no) {
    const payload = {
      firma_k_no: store.getters.getUserData.firma_k_no,
      k_no,
    };
    return await axiosIns.post('/tanimlamalar/iptal-politika-sil', payload).then((res) => {
      commit('SET_IPTAL_POLITIKA_DELETE', k_no);
      return res;
    });
  },
};

export default {
  namespage: true,
  state,
  getters,
  mutations,
  actions,
};
