import { $themeConfig } from '@themeConfig';

const state = {
  isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
};
const getters = {};
const mutations = {
  UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
    state.isVerticalMenuCollapsed = val;
  },
};
const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
