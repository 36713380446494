export const diller = [
  {
    lang: 'tr',
    title: 'Türkçe',
  },
  {
    lang: 'en',
    title: 'İngilizce',
  },
  {
    lang: 'de',
    title: 'Almanca',
  },
  {
    lang: 'ru',
    title: 'Rusça',
  },
  {
    lang: 'nl',
    title: 'Felemenkçe',
  },
  {
    lang: 'ar',
    title: 'Arapça',
  },
];
